import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssistant, MdRefresh } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Барбершоп Стильний Пан
			</title>
			<meta name={"description"} content={"Вітаємо у барбершопі Стильний Пан. Якісні послуги стрижки, гоління та догляду за бородою від професіоналів."} />
			<meta property={"og:title"} content={"Головна | Барбершоп Стильний Пан"} />
			<meta property={"og:description"} content={"Вітаємо у барбершопі Стильний Пан. Якісні послуги стрижки, гоління та догляду за бородою від професіоналів."} />
			<meta property={"og:image"} content={"https://globalgatewaynet.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://globalgatewaynet.com/img/1596393.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://globalgatewaynet.com/img/1596393.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://globalgatewaynet.com/img/1596393.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://globalgatewaynet.com/img/1596393.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://globalgatewaynet.com/img/1596393.png"} />
			<meta name={"msapplication-TileImage"} content={"https://globalgatewaynet.com/img/1596393.png"} />
			<meta name={"msapplication-TileColor"} content={"https://globalgatewaynet.com/img/1596393.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="112px 0 112px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				margin="0px 0px 80px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Барбершоп Стильний Пан
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Вітаємо вас у барбершопі "Модний Джентльмен". Ми створили цей простір для справжніх чоловіків, які цінують стиль, комфорт та якість. У нас ви знайдете не лише професійний догляд за волоссям та бородою, але й атмосферу, де можна розслабитись та насолодитись процесом.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Відвідайте нас
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://globalgatewaynet.com/img/1.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-15"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 24px 0px"
				sm-justify-content="flex-start"
			>
				<Text
					as="p"
					font="--lead"
					color="--primary"
					margin="0px 0px 8px 0px"
					sm-text-align="left"
				>
					<Strong letter-spacing="0.5px">
					Кожна послуга виконується з увагою до деталей
					</Strong>
				</Text>
				<Text
					margin="0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Послуги, які ми пропонуємо
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--dark"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Ми пропонуємо широкий спектр послуг для чоловіків:
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdAssistant}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong letter-spacing="0.5px">
								Класичні та сучасні стрижки
								</Strong>
							</Text>
						</Box>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="fa"
								icon={FaMoneyBillAlt}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
								Гоління небезпечним лезом
								</Strong>
							</Text>
						</Box>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdRefresh}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
								Моделювання бороди
								</Strong>
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Наші клієнти
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
					<Image
						src="https://globalgatewaynet.com/img/2.jpg"
						display="block"
						width="100%"
						min-height="100%"
						object-fit="cover"
					/>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Ми пишаємось тим, що маємо багато постійних клієнтів, які цінують нашу роботу. Ваші відгуки та рекомендації надихають нас ставати ще кращими. Ми завжди раді новим відвідувачам та робимо все можливе, щоб кожен наш клієнт залишився задоволеним.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Зв'яжіться з нами
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Зв'яжіться з нами для запису на послуги чи консультації:
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Телефон
							</Text>
							<Link href="tel:097 004-04-04" color="#000000" text-decoration-line="initial" font="--lead">
								097 004-04-04
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:contact@globalgatewaynet.com" color="#000000" text-decoration-line="initial" font="--lead">
								contact@globalgatewaynet.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});